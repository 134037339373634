<template>
  <div>
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      class="fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.1501 7.49979H7.3426V11.2498C7.3426 11.6623 7.6801 11.9998 8.0926 11.9998H11.0926C11.5051 11.9998 11.8426 11.6623 11.8426 11.2498V7.49979H13.0351C13.7026 7.49979 14.0401 6.68979 13.5676 6.21729L10.1251 2.77479C9.8326 2.48229 9.3601 2.48229 9.0676 2.77479L5.6251 6.21729C5.1526 6.68979 5.4826 7.49979 6.1501 7.49979ZM4.3501 14.2498C4.3501 14.6623 4.6876 14.9998 5.1001 14.9998H14.1001C14.5126 14.9998 14.8501 14.6623 14.8501 14.2498C14.8501 13.8373 14.5126 13.4998 14.1001 13.4998H5.1001C4.6876 13.4998 4.3501 13.8373 4.3501 14.2498Z"
      />
    </svg>
  </div>
</template>
