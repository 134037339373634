import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-2" }
const _hoisted_2 = { class: "w-full pt-2" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["id", "value", "placeholder"]
const _hoisted_6 = { class: "mt-2 flex-shrink-0 self-stretch flex justify-center items-center w-12 h-auto bg-white" }
const _hoisted_7 = { class: "relative w-11 h-11 overflow-hidden cursor-pointer rounded-lg border border-gray-300" }
const _hoisted_8 = ["id", "parentId", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", {
          class: "label-primary",
          for: _ctx.id
        }, _toDisplayString(_ctx.changeCase(_ctx.label, _ctx.ElementE.LABEL)), 9, _hoisted_4),
        _createElementVNode("input", {
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.saveInput && _ctx.saveInput(...args))),
          id: _ctx.id,
          value: _ctx.backColor,
          type: "text",
          class: "input-primary",
          placeholder: (_ctx.changeCase('choose colour', _ctx.ElementE.PLACEHOLDER) as string)
        }, null, 40, _hoisted_5)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("input", {
          id: _ctx.id + 'ccc',
          parentId: _ctx.id,
          type: "color",
          value: _ctx.val,
          class: "absolute -top-2.5 -left-2.5 w-16 h-16 cursor-pointer",
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.pickColor && _ctx.pickColor(...args)))
        }, null, 40, _hoisted_8)
      ])
    ])
  ]))
}