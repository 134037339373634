<template>
  <div>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM7.74223 12.2362L9.96644 14.2773L16.2578 8.50391C16.5056 8.27647 16.8996 8.27647 17.1475 8.50391C17.3953 8.73135 17.3953 9.09291 17.1475 9.32034L10.4113 15.4961C10.1634 15.7235 9.76308 15.7235 9.51524 15.4961L6.85255 13.0526C6.60471 12.8252 6.60471 12.4636 6.85255 12.2362C7.10039 12.0087 7.49439 12.0087 7.74223 12.2362Z"
        fill="#15803D"
      />
    </svg>
  </div>
</template>
