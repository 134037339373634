<template>
  <div>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      class="group-hover:text-primary-main fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.92578 5.25917C7.27126 4.91369 7.82935 4.91369 8.17483 5.25917L12.2409 9.32523C12.5864 9.67071 12.5864 10.2288 12.2409 10.5743L8.17483 14.6403C7.82935 14.9858 7.27126 14.9858 6.92578 14.6403C6.5803 14.2949 6.5803 13.7368 6.92578 13.3913L10.3629 9.94533L6.92578 6.50822C6.5803 6.16274 6.58915 5.5958 6.92578 5.25917Z"
      />
    </svg>
  </div>
</template>
