<template>
  <rr-resnova :widget-id="widgetId" :api-url="`${api}`"></rr-resnova>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "WidgetCompiled",
  props:['widgetId', 'api']
})
</script>

