<template>
  <div
    class="relative w-full pt-2 z-10"
    @click="isOptionsListVisible = !isOptionsListVisible"
    v-click-outside="clickOutside"
  >
    <div class="relative">
      <div class="label-primary">
        {{ changeCase(label, ElementE.LABEL) }}
      </div>

      <div
        class="p-3 border border-gray-300 rounded-lg text-sm text-neutral-125 cursor-default"
        :class="isOptionsListVisible && 'border-red-500'"
      >
        {{ selectedValue }}
      </div>
      <div
        class="absolute top-1/2 right-3 transform -translate-y-1/2 transition duration-300"
        :class="isOptionsListVisible ? '-rotate-90' : 'rotate-90'"
      >
        <DropdownMenuIcon />
      </div>
    </div>

    <div
      v-show="isOptionsListVisible"
      class="absolute w-full mt-2 border border-gray-300 rounded-lg"
      :class="
        values.length > 3 ? 'h-[180px] overflow-y-scroll' : 'overflow-hidden'
      "
    >
      <div
        v-for="item in values"
        :key="item.id"
        :id="item.id"
        class="p-3 text-sm text-neutral-125 cursor-pointer hover:bg-neutral-0"
        :class="item.name.toString() === selectedValue ? 'bg-neutral-25' : 'bg-white'"
        @click="handleSelect"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import DropdownMenuIcon from "../icons/RightArrowIcon.vue";
import { changeCase } from "../../helpers";
import { ElementE } from '../../enums';

export default{
  name: "SourceMenu",
  components: { DropdownMenuIcon },
  props: {
    sources: { type: Object, required: true },
    label: { type: String, required: true },
    defaultSelect: { type: String, default: "Select value" },
    defaultId: { type: String, default: "" },
    values: {
    },
    additional:{ stype: String, required: false },
    tab: { type: String, required: false },
  },
  emits: ["input"],
  watch: {
    values: function (values) {
      this.payments = values;
    },
    additional: function (val){
      this.prop_id = val;
    },
    sources: function (values){
      this.sourceArray = values;
      const pid = this.prop_id;

      if (values.length === 0) {
        this.selectedValue = this.defaultSelect;
        return;
      }

      let defaultVal = values.filter(function (item){
        if(item.principal_id === pid)
          return item
      })

      const selectItem = this.values.filter(function (item){
        if(item.id === defaultVal[0].source_id){
          return item;
        }
      }).map(function(item){
        return item.name
      })

      this.selectedValue = selectItem[0]
    }

  },
  mounted(){
    this.payments = this.values;
    this.sourceArray = this.sources;
    this.prop_id = this.additional
  },
  data() {
    return {
      prop_id: '',
      sourceArray: {},
      payments:{} ,
      isOptionsListVisible: false,
      selectedValue: this.defaultSelect,
      
      changeCase: changeCase,
      ElementE: ElementE,
    };
  },
  methods: {
    clickOutside() {
      this.isOptionsListVisible = false;
    },
    handleSelect(event) {

      const name = event.target.innerText;
      this.selectedValue = name;

      if(this.tab === "display"){
        this.$emit("input", event.target.id);
      }

      if(this.tab === "propery"){
        const fullId = event.target.id + "|" + this.additional
        this.$emit("input", fullId);
      }
    },
  },
}
</script>
