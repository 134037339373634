
import { defineComponent } from "vue";
import { changeCase } from '../../helpers';
import { ElementE } from '../../enums';

export default defineComponent({
  name: "UpdateWidgetModal",
  components: {},
  data() {
    return {
      changeCase: changeCase,
      ElementE: ElementE,
    }
  }
});
