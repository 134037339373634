<template>
  <div class="flex items-center">
    <label class="relative flex items-center cursor-pointer">
      <input
        type="checkbox"
        value=""
        class="sr-only peer"
        :checked="isChecked"
      />
      <div
        class="
          checkbox-primary
          peer-focus:outline
          peer-focus:outline-2
          peer-focus:outline-offset-[3px]
          peer-focus:outline-primary-main
          peer-checked:bg-primary-main
          peer-checked:after:border-white
          peer-checked:after:translate-x-full"
      />
      <span class="ml-3 text-sm font-medium text-gray-900">
        {{ changeCase(label, ElementE.LABEL) }}
      </span>
    </label>

    <div v-if="tooltipTxt" class="relative ml-2">
      <QuestionMarkIcon
        @mouseover="showTooltipTxt = true"
        @mouseleave="showTooltipTxt = false"
      />
      <div
        v-if="showTooltipTxt"
        class="absolute w-72 p-4 rounded-lg bg-white z-10 shadow-secondary"
      >
        <p class="tooltip-content">
          {{ tooltipTxt }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionMarkIcon from "../icons/QuestionMarkIcon.vue";
import { changeCase } from "../../helpers";
import { ElementE } from '../../enums';

export default {
  name: "CheckboxSlide",
  components: { QuestionMarkIcon },
  props: {
    label: { type: String, required: true },
    isChecked: { default: false },
    info: { type: String, default: "" },
    tooltipTxt: { type: String, default: "" },
  },
  emits: ["back"],
  data() {
    return {
      showTooltipTxt: false,

      changeCase: changeCase,
      ElementE: ElementE,
    };
  },
};
</script>
