import { CaseE, ElementE } from '../enums'
import { CaseI } from '../interfaces/interfaces';
import { REQUIRED_FIELDS_FOR_CRDB_AND_DPO_PAYMENTS, REQUIRED_FIELDS_FOR_OTHER_PAYMENTS } from '@/constants';

const cases: CaseI = {
    [ElementE.BUTTON]: CaseE.SENTENCE_CASE,
    [ElementE.TITLE]: CaseE.SENTENCE_CASE,
    [ElementE.SUBTITLE]: CaseE.SENTENCE_CASE,
    [ElementE.DROPDOWN]: CaseE.SENTENCE_CASE,
    [ElementE.LABEL]: CaseE.SENTENCE_CASE,
    [ElementE.INPUT]: CaseE.SENTENCE_CASE,
    [ElementE.PLACEHOLDER]: CaseE.SENTENCE_CASE,
}

export function changeCase(input: string | number, element: ElementE): string | number {
    if (typeof input === 'number') {
        return input;
    }

    switch (cases[element]) {
        case CaseE.SENTENCE_CASE: {
            const sentences = input.match(/[^.!?]+[.!?]+/g);
            if (sentences && sentences.length > 1) {
                return toSentenceCase(sentences);
            }

            return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
        }

        case CaseE.UPPER_CASE:
            return input.toUpperCase();

        case CaseE.LOWER_CASE:
            return input.toLowerCase();

        case CaseE.TITLE_CASE: {
            const words = input.split(' ');
            const titleCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

            return titleCaseWords.join(' ');
        }
    
        default:
            return '';
    }
}

function toSentenceCase(sentences: string[]) {
    const correctedSentences = [];
    for (let i = 0; i < sentences.length; i++) {
        const sentence = sentences[i].trim();
        const correctedSentence = sentence.charAt(0).toUpperCase() + sentence.toLowerCase().slice(1);
        correctedSentences.push(correctedSentence);
    }
        
    const correctedText = correctedSentences.join(' ');
    return correctedText;
}

export const validatePaymentConfig = (payment_gateway_api: string, payment_gateway_config: any) => {
    const requiredFieldsCount = payment_gateway_api === 'crdb' || payment_gateway_api === 'dpo'
        ? REQUIRED_FIELDS_FOR_CRDB_AND_DPO_PAYMENTS
        : REQUIRED_FIELDS_FOR_OTHER_PAYMENTS;
    const values = Object.values(payment_gateway_config);

    if (values.length === requiredFieldsCount) {
      return values?.every(item => item);
    }

    return false;
}
