import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import LoginFormNew from "../components/LoginForm/LoginFormNew.vue";
import DefaultLayout from "@/components/DefaultLayout/DefaultLayout.vue";
import WidgetView from "@/views/WidgetView.vue";
import editWidget from "@/views/EditWidget.vue";
import LogOut from "@/components/LogOut.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: DefaultLayout,
        meta: {
            requiresAuth: true,
        },
        children: [
            { path: "/", name: "Widgets", component: WidgetView },
            { path: "/logout", name: "Logout", component: LogOut },
            {
                path: "/widget/edit/:wid",
                name: "Edit Widget",
                component: editWidget,
            },
        ],
    },
    {
        path: "/login",
        name: "Login",
        component: LoginFormNew,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !store.state.user.token) {
        next({ name: "Login" });
    } else if (store.state.user.token && to.name === "Login") {
        next({ name: "Widgets" });
    } else {
        next();
    }
});

export default router;
