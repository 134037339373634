<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="7"
      viewBox="0 0 8 7"
      fill="none"
    >
      <g clip-path="url(#clip0_2564_1384)">
        <g filter="url(#filter0_dd_2564_1384)">
          <path d="M4 7L-4.66026 -8L12.6603 -8L4 7Z" fill="white" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_2564_1384"
          x="-9.66016"
          y="-11"
          width="27.3203"
          height="25"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_2564_1384"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2564_1384"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="dilate"
            in="SourceAlpha"
            result="effect2_dropShadow_2564_1384"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2564_1384"
            result="effect2_dropShadow_2564_1384"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2564_1384"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2564_1384">
          <rect
            width="8"
            height="7"
            fill="white"
            transform="matrix(-1 0 0 -1 8 7)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
