import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed top-0 left-0 flex justify-center items-center w-full h-full bg-modal z-50"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "flex items-center gap-2" }
const _hoisted_4 = { class: "text-neutral-125 text-base font-normal" }
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = {
  key: 1,
  class: "absolute w-72 p-4 rounded-lg bg-white break-words z-10 shadow-secondary"
}
const _hoisted_7 = { class: "tooltip-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteWidgetModal = _resolveComponent("DeleteWidgetModal")!
  const _component_TooltipIcon = _resolveComponent("TooltipIcon")!
  const _component_TrashCanIcon = _resolveComponent("TrashCanIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isDeleteWidgetModalVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_DeleteWidgetModal, {
            title: _ctx.title,
            "widget-id": _ctx.widget_id,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDeleteWidgetModalVisible = false)),
            onDelete: _ctx.deleteWidget
          }, null, 8, ["title", "widget-id", "onDelete"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      id: _ctx.id,
      class: _normalizeClass(["p-3 bg-white cursor-pointer hover:bg-primary-main/20", _ctx.roundedClasses]),
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push(`/widget/edit/${_ctx.id}`)))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.widget_description != null)
            ? (_openBlock(), _createBlock(_component_TooltipIcon, {
                key: 0,
                onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.descriptionVisibility = true)),
                onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.descriptionVisibility = false))
              }))
            : _createCommentVNode("", true),
          (_ctx.descriptionVisibility)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.widget_description), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          title: "Delete Widget",
          class: "ml-auto",
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.isDeleteWidgetModalVisible = true), ["stop"]))
        }, [
          _createVNode(_component_TrashCanIcon)
        ])
      ])
    ], 10, _hoisted_2)
  ], 64))
}