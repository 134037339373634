
import { defineComponent } from "vue";
import ErrorIcon from "../icons/ErrorIcon.vue";
import { changeCase } from '../../helpers';
import { ElementE } from '../../enums';

export default defineComponent({
  name: "DeleteWidgetModal",
  components: { ErrorIcon },
  props: {
    title: { type: String, default: "" },
    widgetId: { type: String, default: "" },
  },
  data() {
    return {
      changeCase: changeCase,
      ElementE: ElementE,
    }
  },
});
