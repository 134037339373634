<template>
  <div>
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      class="fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4497 14.9997V9.26221C11.4497 9.05221 11.3597 8.84971 11.2022 8.70721L7.45221 5.33221C7.30971 5.19721 7.12971 5.13721 6.94971 5.13721C6.76971 5.13721 6.58971 5.20471 6.44721 5.33221L2.69721 8.70721C2.53971 8.84221 2.44971 9.04471 2.44971 9.26221V14.9997C2.44971 15.4122 2.78721 15.7497 3.19971 15.7497H4.69971C5.11221 15.7497 5.44971 15.4122 5.44971 14.9997V11.9997H8.44971V14.9997C8.44971 15.4122 8.78721 15.7497 9.19971 15.7497H10.6997C11.1122 15.7497 11.4497 15.4122 11.4497 14.9997Z"
      />
      <path
        d="M15.1997 2.25H9.19971C8.78721 2.25 8.44971 2.5875 8.44971 3V4.2075C8.44971 4.2075 8.44971 4.2075 8.45721 4.215L12.2072 7.59C12.6797 8.01 12.9497 8.625 12.9497 9.2625V9.75H14.4497V11.25H12.9497V12.75H14.4497V14.25H12.9497V15.75H15.1997C15.6122 15.75 15.9497 15.4125 15.9497 15V3C15.9497 2.5875 15.6122 2.25 15.1997 2.25ZM11.4497 5.25H9.94971V3.75H11.4497V5.25ZM14.4497 8.25H12.9497V6.75H14.4497V8.25ZM14.4497 5.25H12.9497V3.75H14.4497V5.25Z"
      />
    </svg>
  </div>
</template>
