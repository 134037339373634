
import { defineComponent } from "vue";
import GeneralTabIcon from "../icons/GeneralTabIcon.vue";
import PropertiesTabIcon from "../icons/PropertiesTabIcon.vue";
import DisplayTabIcon from "../icons/DisplayTabIcon.vue";
import StyleTabIcon from "../icons/StyleTabIcon.vue";
import PreviewTabIcon from "../icons/PreviewTabIcon.vue";
import TabArrowDownIcon from "../icons/TabArrowDownIcon.vue";
import { TabI, tabs } from "./constants";
import { changeCase } from "../../helpers";
import { ElementE } from '../../enums'

export default defineComponent({
  name: "TabMenuNew",
  components: {
    GeneralTabIcon,
    PropertiesTabIcon,
    DisplayTabIcon,
    StyleTabIcon,
    PreviewTabIcon,
    TabArrowDownIcon,
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0
    },
  },
  emits: ["sendTabId"],

  data() {
    return {
      tabs: [] as TabI[],
      
      changeCase: changeCase,
      ElementE: ElementE,
    };
  },

  mounted() {
    this.tabs = tabs;
  },

  methods: {
    handleClick(tabName: string, index: number) {
      this.$emit("sendTabId", {tabName, index});
    },

    tabIcon(iconName: string) {
      switch (iconName) {
        case "general":
          return GeneralTabIcon;

        case "properties":
          return PropertiesTabIcon;

        case "display":
          return DisplayTabIcon;

        case "style":
          return StyleTabIcon;

        case "preview":
          return PreviewTabIcon;
      }
    },
  },
});
