
import { defineComponent } from "vue";
import SelectColor from "../SelectColor.vue";
import NotificationPopup from "@/components/notifications/NotificationPopup.vue";
import BaseTooltip from '../notifications/BaseTooltip.vue';
import TooltipIcon from "../icons/TooltipIcon.vue";
import { IColorObject, HtmlEvent, WidgetI } from "@/interfaces/interfaces";
import { changeCase } from "../../helpers";
import { ElementE } from '../../enums'

export default defineComponent({
  name: "StyleTab",
  props:['widgets'],
  emits:['updateWidget'],
  components: {
    SelectColor,
    NotificationPopup,
    BaseTooltip,
    TooltipIcon,
  },
  data() {
    return {
      isShowSavePopup: false,
      widget: {} as WidgetI,
      custom_css: '' as string,

      link_style: '' as string,
      button_colour: '' as string,
      button_hover_colour: '' as string,
      link_hover_style: '' as string,
      button_text_colour: '' as string,
      button_hover_text_colour: '' as string,

      changeCase: changeCase,
      ElementE: ElementE,
    };
  },
  mounted() {
    this.widget = this.widgets;
    this.custom_css = this.widget.custom_css
  },
  watch: {
    widgets: function (widgets) {
      this.widget = widgets

      this.link_style = this.widget.config.link_style

      this.button_colour = this.widget.config.button_colour
      this.button_hover_colour = this.widget.config.button_hover_colour
      this.button_text_colour = this.widget.config.button_text_colour
      this.button_hover_text_colour = this.widget.config.button_hover_text_colour

      this.custom_css = this.widget.custom_css
    }
  },
  methods: {
    setCustomCss(event: HtmlEvent) {
      let custom_css_string = event.target.value;
      this.widget.custom_css = custom_css_string;
      this.isShowSavePopup = true;
      this.$emit('updateWidget', this.widget);
    },
    setColor( colorObject: IColorObject) {
      if(colorObject.id == 'cl1'){
        this.widget.config.link_style = colorObject.color;
        this.link_style = colorObject.color;
      }

      if(colorObject.id == 'cl2'){
        this.widget.config.button_colour = colorObject.color;
        this.button_colour = colorObject.color;
      }

      if(colorObject.id == 'cl3'){
        this.widget.config.button_hover_colour = colorObject.color;
        this.button_hover_colour = colorObject.color;
      }

      if(colorObject.id == 'cl4'){
        this.widget.config.link_hover_style = colorObject.color;
        this.link_hover_style = colorObject.color;
      }

      if(colorObject.id == 'cl5'){
        this.widget.config.button_text_colour = colorObject.color;
        this.button_text_colour = colorObject.color;
      }

      if(colorObject.id == 'cl6'){
        this.widget.config.button_hover_text_colour = colorObject.color;
        this.button_hover_text_colour = colorObject.color;
      }
      this.isShowSavePopup = true;
      this.$emit('updateWidget', this.widget);
    },
  },
});
