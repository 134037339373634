
import { defineComponent } from "vue";
import WarningIcon from "../icons/WarningIcon.vue";
import CopyIcon from "@/components/icons/CopyIcon.vue";
import { getEmbedCode } from '@/common/helpers';
import { changeCase } from '../../helpers';
import { ElementE } from '../../enums';

export default defineComponent({
  name: "UpdateWidgetPopup",
  components: { WarningIcon, CopyIcon },
  emits:['firstUpdateWidget', 'copied'],
  props:{
    widgetId:{
      type: String,
      default: ''
    }
  },
  watch: {
    widgetId: function (widget: string) {
      this.widget = widget;
      this.embedCode = getEmbedCode(this.widget);
      this.$emit('firstUpdateWidget', 1)
    }
  },
  data(){
    return {
      embedCode: '' as string,
      widget: '' as string,

      changeCase: changeCase,
      ElementE: ElementE,
    }
  },
  methods:{
    updateMe(){
      this.$emit('firstUpdateWidget', 1)
      setTimeout(() => {
        window.location.reload()
      }, 500);
    },
    copyEmbedCode: function() {
      this.$emit('copied');
      navigator.clipboard.writeText(this.embedCode);
    }
  },
  mounted() {
    this.widget = this.widgetId;
    this.embedCode = getEmbedCode(this.widget);

  }
});

