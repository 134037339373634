<template>
  <div>
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      class="fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0827 6.84017L11.9602 4.71767C11.6677 4.42517 11.1952 4.42517 10.9027 4.71767L2.51771 13.0952C2.22521 13.3877 2.22521 13.8602 2.51771 14.1527L4.64021 16.2752C4.93271 16.5677 5.40522 16.5677 5.69772 16.2752L14.0752 7.89767C14.3752 7.61267 14.3752 7.13267 14.0827 6.84017ZM11.4577 8.40767L10.4002 7.35017L11.4352 6.31517L12.4927 7.37267L11.4577 8.40767Z"
      />
      <path
        d="M15.4627 10.125L15.0952 10.92L14.3002 11.2875C14.0077 11.4225 14.0077 11.835 14.3002 11.97L15.0952 12.3375L15.4627 13.1325C15.5977 13.425 16.0102 13.425 16.1452 13.1325L16.5127 12.3375L17.3002 11.9625C17.5927 11.8275 17.5927 11.415 17.3002 11.28L16.5052 10.9125L16.1377 10.1175C16.0102 9.83248 15.5902 9.83249 15.4627 10.125Z"
      />
      <path
        d="M7.51269 4.50014L7.88019 3.70514L8.67519 3.33764C8.96769 3.20264 8.96769 2.79014 8.67519 2.65514L7.88019 2.29514L7.51269 1.50014C7.38519 1.20764 6.96519 1.20764 6.83769 1.50014L6.47019 2.29514L5.67519 2.66264C5.38269 2.79764 5.38269 3.21014 5.67519 3.34514L6.47019 3.71264L6.83769 4.50014C6.96519 4.79264 7.38519 4.79264 7.51269 4.50014Z"
      />
      <path
        d="M16.1377 4.50014L16.5052 3.70514L17.3002 3.33764C17.5927 3.20264 17.5927 2.79014 17.3002 2.65514L16.5052 2.28764L16.1377 1.50014C16.0027 1.20764 15.5902 1.20764 15.4552 1.50014L15.0877 2.29514L14.3002 2.66264C14.0077 2.79764 14.0077 3.21014 14.3002 3.34514L15.0952 3.71264L15.4627 4.50014C15.5902 4.79264 16.0102 4.79264 16.1377 4.50014Z"
      />
    </svg>
  </div>
</template>
