<template>
  <div>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      class="fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31093 6.31093C8.72551 5.89636 9.39522 5.89636 9.80979 6.31093L14.6891 11.1902C15.1036 11.6048 15.1036 12.2745 14.6891 12.6891L9.80979 17.5683C9.39522 17.9829 8.72551 17.9829 8.31093 17.5683C7.89636 17.1538 7.89636 16.4841 8.31093 16.0695L12.4355 11.9343L8.31093 7.80979C7.89636 7.39522 7.90699 6.71488 8.31093 6.31093Z"
        fill="white"
      />
    </svg>
  </div>
</template>
