<template>
  <div></div>
</template>

<script>
import store from "@/store";

export default {
  name: "LogOut",
  methods: {
    logout: function () {
      store.dispatch("logout").then(() => {
        location.href = "/login";
      });
    },
  },
  mounted() {
    this.logout();
  },
};
</script>
