
import PropertyBlock from "@/components/edittabs/PropertyBlock.vue";
import {WidgetI} from "@/interfaces/interfaces";
import { defineComponent } from 'vue';
import NotificationPopup from "@/components/notifications/NotificationPopup.vue";
import LoadingIcon from '@/components/icons/LoadingIcon.vue'

export default defineComponent({
  name: "PropertiesTab",
  components: {PropertyBlock, NotificationPopup, LoadingIcon},
  props:{
    widget_info:{
      type: Object
    },
    widgets:{
      type: Object
    }
  },
  emits:['updateWidget'],
  data(){
    return {
      isShowSavePopup: false,
      widget:{} as WidgetI
    }
  },
  computed: {
    principalSources() {
      return this.widget_info?.principal_sources.filter(({ sources }: { sources: [] | null; }) => sources);
    },
  },
  methods:{
    updateWidget(){
      this.widget.edited = true;
      this.$emit('updateWidget', this.widget)
    },
  },
  watch: {
    widgets: function (widgets: WidgetI) {
      this.widget = widgets
    }
  }
})
