<template>
  <div class="flex justify-between p-4 rounded-lg bg-white border border-neutral-50">
    <div class="flex items-center">
      <span class="flex justify-center items-center w-6 h-6 rounded-md border border-white
        hover:border-red-700 transition-all cursor-pointer">
        <input
          :value="id"
          :checked="pids.indexOf(id) >= 0"
          :id="'red-checkbox' + randomId + id"
          type="checkbox"
          class="w-5 h-5 rounded-md text-red-700 border-neutral-100 cursor-pointer
            focus:ring-0 focus:ring-offset-0 transition-all"
          @change="checkProp($event)"
        />
      </span>
      <label
        :for="'red-checkbox' + randomId + id"
        class="ml-2 text-sm text-neutral-125 cursor-pointer"
      >
        {{ title }}
      </label>
    </div>

    <div>
      <label class="flex items-center relative cursor-pointer">
        <input
          @change="checkAdult($event)"
          :checked="adults.indexOf(id) >= 0 && pids.some((item) => item === id)"
          type="checkbox"
          :value="id"
          :data-parentId="'red-checkbox' + randomId + id"
          class="sr-only peer"
          :disabled="!pids.some((item) => item === id)"
        />
        <div
          class="
            checkbox-primary
            peer-focus:outline
            peer-focus:outline-2
            peer-focus:outline-offset-[3px]
            peer-focus:outline-primary-main
            peer-checked:bg-primary-main
            peer-checked:after:border-white
            peer-checked:after:translate-x-full"
        />
        <span class="ml-3 text-sm font-medium text-gray-900">
          {{ changeCase('adults only', ElementE.LABEL) }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { changeCase } from "../../helpers";
import { ElementE } from '../../enums';

export default {
  name: "TwinSelect",
  emits: ["updateWidget"],
  props: {
    title: {
      type: String,
      default: "Name",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    childs: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    widgets: {
      type: Object,
    },
    principalId: {
      type: String,
    },
    principals: {
      type: Object,
    },
  },

  data() {
    return {
      randomId: Math.random(999),
      widget: {},
      pids: [],
      adults: [],

      changeCase: changeCase,
      ElementE: ElementE,
    };
  },
  methods: {
    updateWidget() {
      this.$emit("updateWidget", this.widget);
    },

    getPrincipal(principalId) {
      return this.widget.config.principals.find(
        (item) => item.id === principalId
      );
    },

    addToProperties(principalId, propertyId, adultsOnly) {
      const foundPrincipal = this.getPrincipal(principalId);
      foundPrincipal.properties.push({
        id: propertyId,
        no_children_allowed: adultsOnly,
      });

      const index = this.widget.config.principals.findIndex(
        (item) => item.id === principalId
      );
      this.widget.config.principals[index] = foundPrincipal;
    },

    addToPrincipals(principalId, propertyId, adultsOnly, foundPrincipal) {
      if (!foundPrincipal) {
        this.widget.config.principals.push({
          id: principalId,
          properties: [{ id: propertyId, no_children_allowed: adultsOnly }],
        });
      } else {
        this.addToProperties(principalId, propertyId, adultsOnly);
      }
    },

    removeFromPrincipals(principalId, propertyId) {
      const foundPrincipal = this.getPrincipal(principalId);
      const updatedProperties = foundPrincipal.properties.filter(
        (item) => item.id !== propertyId
      );

      foundPrincipal.properties = updatedProperties;
    },

    checkProp(e, idVal = null, isCheckedVal = null, adultsOnly = false) {
      let isChecked = false;
      let id = "";
      if (idVal != null && isCheckedVal != null) {
        isChecked = true;
        id = idVal;
      } else {
        isChecked = e.target.checked;
        id = e.target.value;
      }
      
      let principalId = this.principalId;
      this.pids = this.pids.filter((item) => item !== principalId);

      if (isChecked) {
        const foundPrincipal = this.getPrincipal(principalId);
        this.addToPrincipals(principalId, id, adultsOnly, foundPrincipal);
      }

      if (!isChecked) {
        this.removeFromPrincipals(principalId, id);
      }

      this.updateWidget();
    },
    checkAdult(e) {
      let propertyId = e.target.getAttribute("value");
      this.widget.config.principals = this.widget.config.principals.map(
        (item) => {
          const properties = item.properties.map((property) => {
            if (property.id === propertyId) {
              return { ...property, no_children_allowed: e.target.checked };
            } else {
              return property;
            }
          });
          return { ...item, properties };
        }
      );
      this.updateWidget();
    },
  },
  mounted() {
    this.widget = this.widgets;
  },
  watch: {
    widgets: {
      handler(widgets) {
        this.widget = widgets;
        this.pids = [];
        this.adults = [];
        widgets.config.principals.forEach((item) => {
          item.properties.forEach((it) => {
            if (!this.pids.indexOf(it.id) >= 0) {
              this.pids.push(it.id);
            }
            if (!this.adults.indexOf(it.id) >= 0) {
              if (it.no_children_allowed === true) {
                this.adults.push(it.id);
              }
            }
          });
        });
      },
      deep: true,
    },
  },
};
</script>
