export enum CaseE {
    SENTENCE_CASE = 'sentence',
    UPPER_CASE = 'upper',
    LOWER_CASE = 'lower',
    TITLE_CASE = 'title',
}

export enum ElementE {
    BUTTON = 'button',
    TITLE = 'title',
    SUBTITLE = 'subtitle',
    DROPDOWN = 'dropdown',
    LABEL = 'label',
    INPUT = 'input',
    PLACEHOLDER = 'placeholder',
}

export enum EmailFieldsE {
    CONTACT_EMAIL = 'contact_email',
    CC_EMAILS = 'cc_email',
    ORIGINATING_EMAIL = 'originating_email',
}