import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-751bd59e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "uwfb",
  class: "z-[9999] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
}
const _hoisted_2 = { class: "flex flex-col gap-6 w-[650px] p-6 rounded-lg bg-white shadow-default" }
const _hoisted_3 = { class: "flex gap-4" }
const _hoisted_4 = { class: "warning flex justify-center items-center flex-shrink-0 w-10 h-10 rounded-sm" }
const _hoisted_5 = { class: "modal-heading" }
const _hoisted_6 = { class: "modal-content mt-1" }
const _hoisted_7 = { class: "pt-2" }
const _hoisted_8 = { class: "relative" }
const _hoisted_9 = ["value", "placeholder"]
const _hoisted_10 = { class: "self-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WarningIcon = _resolveComponent("WarningIcon")!
  const _component_CopyIcon = _resolveComponent("CopyIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_WarningIcon)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.changeCase("widget upgraded successfully!", _ctx.ElementE.TITLE)), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.changeCase("please publish the changes and insert the new embed code on your website.", _ctx.ElementE.SUBTITLE)), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyEmbedCode && _ctx.copyEmbedCode(...args))),
            class: "label-primary flex gap-1 cursor-pointer hover:opacity-80",
            for: "embed-code"
          }, [
            _createTextVNode(_toDisplayString(_ctx.changeCase("embed code", _ctx.ElementE.LABEL)) + " ", 1),
            _createVNode(_component_CopyIcon)
          ]),
          _createElementVNode("textarea", {
            value: _ctx.embedCode,
            id: "embed-code",
            name: "",
            disabled: "",
            rows: "5",
            class: "w-full text-sm p-3 border border-gray-300 rounded-lg focus:border-red-500 focus:ring-0",
            placeholder: (_ctx.changeCase('embed code', _ctx.ElementE.PLACEHOLDER) as string)
          }, null, 8, _hoisted_9)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateMe && _ctx.updateMe(...args)), ["prevent"])),
          class: "btn-primary is-btn-size-default"
        }, _toDisplayString(_ctx.changeCase("I've updated embed code", _ctx.ElementE.BUTTON)), 1)
      ])
    ])
  ]))
}