
import { defineComponent } from 'vue';
import { changeCase } from '../../helpers';
import { ElementE } from '../../enums';

export default defineComponent({
  name: "SaveWidgetBtn",
  props: {
    title: {
      type: String,
      default: "Save & Publish"
    },
    visible: {
      default: false
    }
  },
  emits: ['saveClick'],
  methods: {
    saveWidget: function(){
      this.$emit('saveClick', true)
    },
  },
  data() {
    return {
      changeCase: changeCase,
      ElementE: ElementE,
    }
  },
})
