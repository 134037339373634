import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "style_tab",
  class: "my-6"
}
const _hoisted_2 = { class: "tab-wrapper-primary" }
const _hoisted_3 = { class: "tab-heading-primary" }
const _hoisted_4 = { class: "p-6" }
const _hoisted_5 = { class: "relative flex gap-4" }
const _hoisted_6 = { class: "flex flex-col gap-4 w-1/2" }
const _hoisted_7 = { class: "flex flex-col gap-4 w-1/2" }
const _hoisted_8 = { class: "w-full pt-2 mt-4" }
const _hoisted_9 = { class: "relative" }
const _hoisted_10 = {
  class: "label-primary flex gap-1",
  for: ""
}
const _hoisted_11 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationPopup = _resolveComponent("NotificationPopup")!
  const _component_SelectColor = _resolveComponent("SelectColor")!
  const _component_TooltipIcon = _resolveComponent("TooltipIcon")!
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.widget.edited)
      ? (_openBlock(), _createBlock(_component_NotificationPopup, {
          key: 0,
          status: "warning",
          title: "Changes Unpublished",
          text: "To view your updates on the widget, please save and publish them.",
          class: "mb-6"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.changeCase('customise design', _ctx.ElementE.TITLE)), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_SelectColor, {
              value: _ctx.link_style,
              id: "cl1",
              label: "Link colour",
              onColors: _ctx.setColor
            }, null, 8, ["value", "onColors"]),
            _createVNode(_component_SelectColor, {
              value: _ctx.button_colour,
              id: "cl2",
              label: "Button Colour (Secondary Button Text Color)",
              onColors: _ctx.setColor
            }, null, 8, ["value", "onColors"]),
            _createVNode(_component_SelectColor, {
              id: "cl3",
              value: _ctx.button_hover_colour,
              label: "Button Hover Colour (Secondary Button Hover Text Color)\n\n  ",
              onColors: _ctx.setColor
            }, null, 8, ["value", "onColors"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_SelectColor, {
              value: _ctx.button_text_colour,
              id: "cl5",
              label: "Button Text Colour (Secondary Button Color)",
              onColors: _ctx.setColor
            }, null, 8, ["value", "onColors"]),
            _createVNode(_component_SelectColor, {
              value: _ctx.button_hover_text_colour,
              id: "cl6",
              label: "Button Hover Text Colour (Secondary Button Hover Color)",
              onColors: _ctx.setColor
            }, null, 8, ["value", "onColors"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, [
              _createTextVNode(" Custom CSS "),
              _createVNode(_component_BaseTooltip, { "tooltip-text": "For a sample of the custom CSS code, please refer to the developer notes accessible from the menu in the upper right corner" }, {
                icon: _withCtx(() => [
                  _createVNode(_component_TooltipIcon)
                ]),
                _: 1
              })
            ]),
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.custom_css) = $event)),
              onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setCustomCss && _ctx.setCustomCss(...args))),
              class: "input-primary h-40",
              placeholder: (_ctx.changeCase('enter code...', _ctx.ElementE.PLACEHOLDER) as string)
            }, null, 40, _hoisted_11), [
              [_vModelText, _ctx.custom_css]
            ])
          ])
        ])
      ])
    ])
  ]))
}