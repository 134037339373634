<template>
  <div>
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      class="fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.0249 11.25H7.1499V8.25H6.0249V9.1875H4.8999V10.3125H6.0249V11.25Z"
      />
      <path d="M13.8999 9.1875H7.8999V10.3125H13.8999V9.1875Z" />
      <path
        d="M12.7749 7.3125H13.8999V6.1875H12.7749V5.25H11.6499V8.25H12.7749V7.3125Z"
      />
      <path d="M10.8999 6.1875H4.8999V7.3125H10.8999V6.1875Z" />
      <path
        d="M15.3999 2.25H3.3999C2.5749 2.25 1.8999 2.925 1.8999 3.75V12.75C1.8999 13.575 2.5674 14.25 3.3999 14.25H6.3999V15C6.3999 15.4125 6.7374 15.75 7.1499 15.75H11.6499C12.0624 15.75 12.3999 15.4125 12.3999 15V14.25H15.3999C16.2249 14.25 16.8999 13.575 16.8999 12.75V3.75C16.8999 2.9175 16.2249 2.25 15.3999 2.25ZM15.3999 12.75H3.3999V3.75H15.3999V12.75Z"
      />
    </svg>
  </div>
</template>
