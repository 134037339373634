<template>
  <div class="text-neutral-75 group">
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      class="group-hover:text-neutral-125 fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2604 9.43491C18.2604 14.2374 14.3673 18.1306 9.56479 18.1306C4.76232 18.1306 0.869141 14.2374 0.869141 9.43491C0.869141 4.63243 4.76232 0.739258 9.56479 0.739258C14.3673 0.739258 18.2604 4.63243 18.2604 9.43491ZM7.68193 7.26358C7.33172 7.11474 7.18287 6.68135 7.393 6.36616C7.81764 5.73577 8.52682 5.27612 9.46364 5.27612C10.4924 5.27612 11.1972 5.74453 11.5562 6.33114C11.8626 6.83457 12.0421 7.77577 11.5693 8.4762C11.2988 8.8752 11.0341 9.13143 10.8039 9.35426C10.587 9.56416 10.4008 9.74443 10.2691 9.9865C10.2035 10.1047 10.1641 10.201 10.1378 10.398C10.0984 10.7176 9.83574 10.9671 9.5118 10.9671C9.13094 10.9671 8.82012 10.6388 8.8639 10.2579C8.89017 10.0347 8.9427 9.80263 9.06527 9.58375C9.25238 9.246 9.53524 8.98512 9.81429 8.72776C10.038 8.52145 10.2592 8.31741 10.4267 8.07783C10.8251 7.51311 10.6018 6.45809 9.4724 6.45809C8.96021 6.45809 8.62751 6.72513 8.42176 7.0447C8.26854 7.29423 7.94897 7.37302 7.68193 7.26358ZM9.46802 13.5937C9.94956 13.5937 10.3436 13.1997 10.3436 12.7182C10.3436 12.2366 9.94956 11.8426 9.46802 11.8426C8.98648 11.8426 8.59249 12.2366 8.59249 12.7182C8.59249 13.1997 8.98648 13.5937 9.46802 13.5937Z"
      />
    </svg>
  </div>
</template>
