import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "preview_tab",
  class: "my-6"
}
const _hoisted_2 = { class: "tab-wrapper-primary" }
const _hoisted_3 = { class: "tab-heading-primary" }
const _hoisted_4 = { class: "flex flex-col gap-4 p-6" }
const _hoisted_5 = { class: "w-full pt-2" }
const _hoisted_6 = { class: "relative" }
const _hoisted_7 = {
  class: "label-primary flex gap-1",
  for: "embed-code"
}
const _hoisted_8 = { class: "tab-wrapper-primary mt-6" }
const _hoisted_9 = { class: "tab-heading-primary" }
const _hoisted_10 = { class: "flex flex-col gap-4 p-6 overflow-scroll" }
const _hoisted_11 = { class: "relative h-[700px] rounded-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationPopup = _resolveComponent("NotificationPopup")!
  const _component_CopyIcon = _resolveComponent("CopyIcon")!
  const _component_WidgetCompiled = _resolveComponent("WidgetCompiled")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.widget.edited)
      ? (_openBlock(), _createBlock(_component_NotificationPopup, {
          key: 0,
          status: "warning",
          title: "Changes Unpublished",
          text: "To view your updates on the widget, please save and publish them.",
          class: "mb-6"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.changeCase('additional', _ctx.ElementE.TITLE)), 1),
      _createElementVNode("div", _hoisted_4, [
        (!this.widget.edited)
          ? (_openBlock(), _createBlock(_component_NotificationPopup, {
              key: 0,
              status: "info",
              title: "Your widget is ready for publication!",
              text: "Copy and paste the embed code into your secure website to display the widget."
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, [
              _createTextVNode(_toDisplayString(_ctx.changeCase('embed code', _ctx.ElementE.LABEL)) + " ", 1),
              _createVNode(_component_CopyIcon, {
                onClick: _ctx.copyEmbedCode,
                class: "cursor-pointer hover:opacity-80"
              }, null, 8, ["onClick"])
            ]),
            _withDirectives(_createElementVNode("textarea", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectAll && _ctx.selectAll(...args))),
              ref: "textarea",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.embedCode) = $event)),
              id: "embed-code",
              class: "input-primary",
              readonly: ""
            }, null, 512), [
              [_vModelText, _ctx.embedCode]
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.changeCase('preview', _ctx.ElementE.TITLE)), 1),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_WidgetCompiled, {
            api: _ctx.api,
            "widget-id": _ctx.widgets.id
          }, null, 8, ["api", "widget-id"])
        ])
      ])
    ])
  ]))
}