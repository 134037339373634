import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "fixed top-0 left-0 flex justify-center items-center w-full h-full bg-modal z-50"
}
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { class: "items-center text-center" }
const _hoisted_4 = {
  key: 0,
  class: "sr-only"
}
const _hoisted_5 = {
  key: 3,
  id: "editWidgetFlow"
}
const _hoisted_6 = { class: "flex justify-between" }
const _hoisted_7 = { class: "mt-8 mb-6 text-lg" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "flex justify-end gap-4 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToastPopup = _resolveComponent("ToastPopup")!
  const _component_UpdateWidgetModal = _resolveComponent("UpdateWidgetModal")!
  const _component_UpdateWidgetPopup = _resolveComponent("UpdateWidgetPopup")!
  const _component_UpdateWidget = _resolveComponent("UpdateWidget")!
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!
  const _component_SaveWidgetBtn = _resolveComponent("SaveWidgetBtn")!
  const _component_TabMenuNew = _resolveComponent("TabMenuNew")!
  const _component_GeneralTab = _resolveComponent("GeneralTab")!
  const _component_PropertiesTab = _resolveComponent("PropertiesTab")!
  const _component_DisplayTab = _resolveComponent("DisplayTab")!
  const _component_StyleTab = _resolveComponent("StyleTab")!
  const _component_PreviewTab = _resolveComponent("PreviewTab")!
  const _component_PrevBtn = _resolveComponent("PrevBtn")!
  const _component_NextBtn = _resolveComponent("NextBtn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showCopyToast)
      ? (_openBlock(), _createBlock(_component_ToastPopup, {
          key: 0,
          status: "info",
          text: "copied to clipboard...",
          class: "w-1/4",
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCopyToast = false))
        }))
      : _createCommentVNode("", true),
    (_ctx.showUpdateWidgetModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_UpdateWidgetModal, {
            onDiscardChanges: _ctx.discardChanges,
            onReturnToEditing: _ctx.returnToEditing
          }, null, 8, ["onDiscardChanges", "onReturnToEditing"])
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_UpdateWidgetPopup, {
      "widget-id": this.widget.id,
      onFirstUpdateWidget: _ctx.firstUpdatedWidget,
      onCopied: _ctx.copyToClipboard
    }, null, 8, ["widget-id", "onFirstUpdateWidget", "onCopied"]), [
      [_vShow, _ctx.isUpdated]
    ]),
    (_ctx.isOldWidget)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_UpdateWidget, { onUpdateInfo: _ctx.updateMyWidget }, null, 8, ["onUpdateInfo"]),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isLoadIcon)
              ? (_openBlock(), _createBlock(_component_LoadingIcon, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          (_ctx.isLoadIcon)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Loading..."))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h1", _hoisted_7, [
              _createElementVNode("span", {
                class: "my-widgets-link",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/')))
              }, _toDisplayString(_ctx.changeCase('my widgets', _ctx.ElementE.TITLE)), 1),
              _createTextVNode(" / " + _toDisplayString(_ctx.widget.name), 1)
            ]),
            (_ctx.showErrorToast)
              ? (_openBlock(), _createBlock(_component_ToastPopup, {
                  key: 0,
                  status: "error",
                  text: _ctx.errorMessage,
                  onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showErrorToast = false))
                }, null, 8, ["text"]))
              : _createCommentVNode("", true),
            _createVNode(_component_SaveWidgetBtn, {
              onClick: _withModifiers(_ctx.saveWidget, ["prevent"]),
              visible: _ctx.saveBtnVisibility
            }, null, 8, ["onClick", "visible"])
          ]),
          (!_ctx.isError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_TabMenuNew, {
                  "active-index": _ctx.activeIndex,
                  onSendTabId: _ctx.loadTab
                }, null, 8, ["active-index", "onSendTabId"]),
                _createVNode(_component_GeneralTab, {
                  onUpdateWidget: _ctx.updateWidget,
                  widgets: this.widget,
                  paymentProp: _ctx.paymentMethods
                }, null, 8, ["onUpdateWidget", "widgets", "paymentProp"]),
                _createVNode(_component_PropertiesTab, {
                  widget_info: _ctx.widgetInfo,
                  onUpdateWidget: _ctx.updateWidget,
                  widgets: this.widget
                }, null, 8, ["widget_info", "onUpdateWidget", "widgets"]),
                _createVNode(_component_DisplayTab, {
                  widget_info: _ctx.widgetInfo,
                  onUpdateWidget: _ctx.updateWidget,
                  widgets: this.widget
                }, null, 8, ["widget_info", "onUpdateWidget", "widgets"]),
                _createVNode(_component_StyleTab, {
                  onUpdateWidget: _ctx.updateWidget,
                  widgets: this.widget
                }, null, 8, ["onUpdateWidget", "widgets"]),
                (_ctx.isForceUpdate)
                  ? (_openBlock(), _createBlock(_component_PreviewTab, {
                      key: 0,
                      onUpdateWidget: _ctx.updateWidget,
                      widgets: this.widget,
                      onCopied: _ctx.copyToClipboard
                    }, null, 8, ["onUpdateWidget", "widgets", "onCopied"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_PrevBtn, {
                    visible: _ctx.prev,
                    onSendTabId: _ctx.loadPrevBtn
                  }, null, 8, ["visible", "onSendTabId"]),
                  _createVNode(_component_NextBtn, {
                    visible: _ctx.next,
                    onSendTabId: _ctx.loadNextTab
                  }, null, 8, ["visible", "onSendTabId"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}