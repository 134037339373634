
import { defineComponent } from "vue";
import NotificationPopup from "../../components/notifications/NotificationPopup.vue";
import PaymentDropdown from "@/components/inputs/PaymentDropdown.vue";

import {
  FilledObjectI,
  PaymentFieldsI,
  PaymentMethodsI,
  PaymentResultObject,
  WidgetI,
} from "@/interfaces/interfaces";

import CheckboxSlide from "../inputs/CheckboxSlide.vue";
import BaseTooltip from "../notifications/BaseTooltip.vue";
import QuestionMarkIcon from "../icons/QuestionMarkIcon.vue";
import PaymentTextInput from "../inputs/PaymentTextInput.vue";
import ErrorIcon from "../icons/ErrorIcon.vue";
import { WIDGET_FIELD_MAX_LENGTH, WIDGET_DESCRIPTION_MAX_LENGTH } from '../../constants'
import { changeCase, validatePaymentConfig } from "../../helpers";
import {
  ElementE,
  EmailFieldsE,
} from '../../enums'

interface HtmlEvent extends Event {
  target: HTMLInputElement;
}

interface ErrorI {
  widgetName: string;
  widgetDescription: string;
  contactEmail: string;
  ccEmails: string;
  originatingEmail: string;
  personName: string;
  [key: string]: string;
}

export default defineComponent({
  name: "GeneralTab",
  emits: ["updateWidget"],
  components: {
    CheckboxSlide,
    BaseTooltip,
    NotificationPopup,
    PaymentTextInput,
    ErrorIcon,
    PaymentDropdown,
    QuestionMarkIcon,
  },
  props: ["widgets", "paymentProp", "saveLabel"],
  data() {
    return {
      isShowPaymentError: false,
      allowPayFull: true,
      allowPayLater: true,
      isSimplePaymentField: false,
      paymentMethod: "Choose payment method" as string,
      email: "" as string,
      additionalEmail: "" as string | null,
      name: "" as string | null,
      isBookingChecked: false as boolean,
      isPaymentChecked: false as boolean,
      widget: {} as WidgetI,
      payment_gateway_config: {},
      payments: {} as PaymentMethodsI,
      paymentFields: [] as PaymentFieldsI[],
      updatedPaymentFields: [] as PaymentFieldsI[],
      contactEmailFieldValue: "",
      ccEmailsFieldValue: "",
      originatingEmailFieldValue: "",
      personNameFieldValue: "",
      errors: {
        widgetName: "",
        widgetDescription: "",
        contactEmail: "",
        ccEmails: "",
        originatingEmail: "",
        personName: "",
      } as ErrorI,
      changeCase: changeCase,
      ElementE: ElementE,
      EmailFieldsE,
    };
  },
  computed: {
    errorTextWithSupportLink() {
      const supportEmail = 'support@resrequest.com';
      return `Credit card payments aren't enabled due to an incorrect payment gateway setup in ResRequest.
        Please check the setup or contact our <a href="mailto:${supportEmail}" class="underline hover:opacity-80">support team</a>.`;
    },
  },
  mounted() {
    this.widget = this.widgets;
    this.payments = this.paymentProp;
    if(this.widgets.config != undefined &&
      this.widgets.config.payment_gateway_api != undefined &&
      this.widgets.config.payment_gateway_config.length !== 0){
      this.paymentMethod = this.widgets.config.payment_gateway_api;
    }
  },
  watch: {
    isPaymentChecked(value) {
      if (value) {
        if (this.widget.config.payment_gateway_api && this.widget.config.payment_gateway_config) {
          this.isShowPaymentError = !validatePaymentConfig(this.widget.config.payment_gateway_api, this.widget.config.payment_gateway_config);
        }
      }
    },

    isBookingChecked(booleanValue) {
      if (!booleanValue && !this.contactEmailFieldValue) {
        this.errors.contactEmail = "Email is required";
      }
    },

    paymentProp: function (paymentProp: PaymentMethodsI) {
      this.payments = paymentProp;
    },
    widgets: function (widgets: WidgetI) {
      this.widget = widgets;
      if (
        widgets.config !== undefined &&
        widgets.config.enable_bookings !== undefined
      ) {
        this.isBookingChecked = widgets.config.enable_bookings;
      }

      if (
        widgets.config !== undefined &&
        widgets.config.enable_card_payments !== undefined
      ) {
        this.isPaymentChecked = widgets.config.enable_card_payments;
      }

      if (this.widgets.config.contact_email != undefined) {
        this.contactEmailFieldValue = this.widgets.config.contact_email;
      }

      if (this.widget.config.name != undefined) {
        this.name = this.widget.config.name;
      } else {
        this.widget.config.name = "";
      }

      if (this.widgets.config.cc_emails != undefined) {
        this.ccEmailsFieldValue = this.widgets.config.cc_emails;
      }

      if (this.widgets.config.name != undefined) {
        this.personNameFieldValue = this.widgets.config.name;
      }

      if (this.widgets.config.originating_email != undefined) {
        this.originatingEmailFieldValue = this.widgets.config.originating_email;
      }

      if (this.widgets.config.payment_gateway_api != undefined
        && (this.widgets.config.payment_gateway_config === undefined
          || this.widgets.config.payment_gateway_config.length !== 0)) {
        this.paymentMethod = this.widgets.config.payment_gateway_api;
      }

      if (this.widgets.config.can_pay_full != undefined) {
        this.allowPayFull = this.widgets.config.can_pay_full;
      }

      if (this.widgets.config.can_pay_later != undefined) {
        this.allowPayLater = this.widgets.config.can_pay_later;
      }

      if (this.widget.config.payment_gateway_config != undefined && this.widgets.config.payment_gateway_config.length !== 0) {
        if (
          Object.keys(this.widget.config.payment_gateway_config).length > 0 &&
          this.widget.config.payment_gateway_config.constructor === Object
        ) {
          this.isSimplePaymentField = true;
        }
        this.payment_gateway_config = this.widget.config.payment_gateway_config;
        this.updatedPaymentFields = Object.keys(this.widget.config.payment_gateway_config).map(item => {return {field_name: item}});
        this.paymentFields = Object.keys(this.widget.config.payment_gateway_config).map(item => {return {field_name: item}});
      }
    },
  },
  methods: {
    validateField(fieldName: string, value: string) {
      if (fieldName === "widgetName" && !value) {
        this.errors[fieldName] = "The field should not be empty";
        return;
      }

      if (fieldName === "widgetDescription") {
        if (value && value.length > WIDGET_DESCRIPTION_MAX_LENGTH) {
          this.errors[
            fieldName
          ] = `The length of field must be less than ${WIDGET_DESCRIPTION_MAX_LENGTH} characters`;
          return;
        }
      } else {
        if (value && value.length > WIDGET_FIELD_MAX_LENGTH) {
          this.errors[
            fieldName
          ] = `The length of field must be less than ${WIDGET_FIELD_MAX_LENGTH} characters`;
          return;
        }
      }

      this.errors[fieldName] = "";
    },

    validateEmail(fieldName: string, value: string) {
      const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      const urlRegex = /^(https?:\/\/(www\.)?|www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^?\s]*)?(\?[^\s]*)?$/;
      
      if (fieldName === 'contactEmail') {
        if (!value) {
          this.errors[fieldName] = "The field should not be empty";
          return;
        }

        if (value && (!emailRegex.test(value) && !urlRegex.test(value))) {
          this.errors[fieldName] = "Should be a vaild email or url address";
          return;
        }
      } else if (fieldName === 'ccEmails') {
        if (!value) return;

        const emails = value.split(/[;,]/).map((email) => email.trim().toLocaleLowerCase());
        const isMultipleEmailsFieldValid = emails.every((m) => emailRegex.test(m));
        if (!isMultipleEmailsFieldValid) {
          this.errors[fieldName] = "Should be a vaild email address / addresses";
          return;
        }
      } else {
        if (value && !emailRegex.test(value)) {
          this.errors[fieldName] = "Should be a vaild email address";
          return;
        }
      }

      this.errors[fieldName] = "";
    },

    payFull(event: HtmlEvent) {
      this.allowPayFull = event.target.checked;
      this.widget.config.can_pay_full = event.target.checked;
      this.updateWidget();
    },
    payLater(event: HtmlEvent) {
      this.allowPayLater = event.target.checked;
      this.widget.config.can_pay_later = event.target.checked;
      this.updateWidget();
    },

    saveWidgetName(inputValue: string) {
      this.validateField('widgetName', inputValue);

      if (this.widget && this.widget.config) {
        this.widget.name = inputValue;
        this.updateWidget();
      }
    },

    saveWidgetDescription(inputValue: string) {
      this.validateField('widgetDescription', inputValue);

      if (this.widget && this.widget.config) {
        this.widget.description = inputValue;
        this.updateWidget();
      }
    },

    saveContactEmail: function (inputValue: string) {
      if (this.widget && this.widget.config) {
        this.widget.config.contact_email = inputValue;
        this.updateWidget();
      }
    },

    saveCcEmails(inputValue: string) {
      if (!this.widget?.config) return;
      this.widget.config.cc_emails = inputValue;
      this.updateWidget();
    },

    saveOriginatingEmail: function (inputValue: string) {
      if (this.widget && this.widget.config) {
        this.widget.config.originating_email = inputValue;
        this.updateWidget();
      }
    },

    savePField: function (inputValue: string) {
      if (this.widget && this.widget.config) {
        this.widget.config.originating_email = inputValue;
        this.updateWidget();
      }
    },

    saveName: function (inputValue: string) {
      if (this.widget && this.widget.config) {
        this.widget.config.name = inputValue;
        this.updateWidget();
      }
    },

    updateWidget() {
      this.$emit("updateWidget", this.widget);
    },

    paymentEnable(event: HtmlEvent) {
      if (event.target.checked === true) {
        this.isShowPaymentError = true;
        if (
          this.widget.config !== undefined &&
          this.widget.config.enable_card_payments != undefined
        ) {
          this.widget.config.enable_card_payments = true;
          this.isPaymentChecked = true;
        }
      } else if (event.target.checked === false) {
        this.isShowPaymentError = false;
        this.allowPayFull = true;
        this.allowPayLater = true;
        if (
          this.widget.config !== undefined &&
          this.widget.config.enable_card_payments != undefined
        ) {
          this.widget.config.enable_card_payments = false;
          this.isPaymentChecked = false;
          this.widget.config.can_pay_full = true;
          this.widget.config.can_pay_later = true;
        }
      }

      this.updateWidget();
    },

    bookingEnable(event: HtmlEvent) {
      if (event.target.checked === true) {
        if (
          this.widget.config !== undefined &&
          this.widget.config.enable_bookings != undefined
        ) {
          this.widget.config.enable_bookings = true;
          this.isBookingChecked = true;
          this.widget.config.can_pay_full = true;
          this.widget.config.can_pay_later = true;
        }
      } else if (event.target.checked === false) {
        this.isBookingChecked = false;
        if (
          this.widget.config !== undefined &&
          this.widget.config.enable_bookings != undefined
        ) {
          this.widget.config.enable_bookings = false;
        }
      }

      this.updateWidget();
    },

    paymentSet(field_slug: FilledObjectI) {
      this.isSimplePaymentField = false;
      this.paymentMethod = field_slug.slug;
      this.widget.config.payment_gateway_api = field_slug.slug;
      this.paymentFields = JSON.parse(JSON.stringify(field_slug.payOptions));
      this.widget.config.payment_gateway_config = {};
      this.updatedPaymentFields = [];
      this.isShowPaymentError = true;
      this.updateWidget();
    },
    savePaymentField(payment: PaymentResultObject) {
      const arrayPaymentFields = JSON.parse(JSON.stringify(this.paymentFields))

      if(payment.val.length === 0) {
        this.updatedPaymentFields = this.updatedPaymentFields.filter((item) => (item.field_name !== payment.id))
      }

      if(!this.updatedPaymentFields.some((item) => (item.field_name === payment.id)) && payment.val.length !== 0) {
        const findPayment = arrayPaymentFields.find((item: { field_name: string; }) => (payment.id === item.field_name));
        this.updatedPaymentFields = [...this.updatedPaymentFields, findPayment];
      }

      this.isShowPaymentError = this.updatedPaymentFields.length !== arrayPaymentFields.length;

      Object.assign(this.widget.config.payment_gateway_config, {
        [payment.id]: payment.val,
      });

      this.updateWidget();
    },
  },
});
