<template>
  <div>
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.72943 19.0005H20.2594C21.0294 19.0005 21.5094 18.1705 21.1294 17.5005L11.8594 1.50055C11.4694 0.830547 10.5094 0.830547 10.1294 1.50055L0.859434 17.5005C0.479434 18.1705 0.959434 19.0005 1.72943 19.0005ZM11.9994 16.0005H9.99943V14.0005H11.9994V16.0005ZM10.9994 12.0005C10.4494 12.0005 9.99943 11.5505 9.99943 11.0005V9.00055C9.99943 8.45055 10.4494 8.00055 10.9994 8.00055C11.5494 8.00055 11.9994 8.45055 11.9994 9.00055V11.0005C11.9994 11.5505 11.5494 12.0005 10.9994 12.0005Z"
        fill="#A76506"
      />
    </svg>
  </div>
</template>
