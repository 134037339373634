
import axiosClient from "../axiosClient";
import store from '@/store';
import TabMenuNew from "../components/menus/TabMenuNew.vue";
import UpdateWidgetPopup from "@/components/notifications/UpdateWidgetPopup.vue";
import GeneralTab from "../components/edittabs/GeneralTab.vue";
import PropertiesTab from "../components/edittabs/PropertiesTab.vue";
import DisplayTab from "../components/edittabs/DisplayTab.vue";
import StyleTab from "../components/edittabs/StyleTab.vue";
import PreviewTab from "../components/edittabs/PreviewTab.vue";
import NextBtn from "../components/buttons/NextBtn.vue";
import PrevBtn from "../components/buttons/PrevBtn.vue";
import SaveWidgetBtn from "../components/buttons/SaveWidgetBtn.vue";
import LoadingIcon from '../components/icons/LoadingIcon.vue';
import { defineComponent } from "vue";
import { WidgetI, PaymentMethodsI } from "@/interfaces/WidgetI";
import UpdateWidget from "@/components/notifications/UpdateWidget.vue";
import ToastPopup from "../components/notifications/ToastPopup.vue";
import {
  WIDGET_FIELD_MAX_LENGTH as WIDGET_NAME_MAX_LENGTH,
  WIDGET_DESCRIPTION_MAX_LENGTH,
} from '../constants';
import UpdateWidgetModal from "../components/modals/UpdateWidgetModal.vue";
import { changeCase, validatePaymentConfig } from "../helpers";
import { ElementE } from '../enums';

export default defineComponent({
  name: "EditWidget",
  components: {
    UpdateWidget,
    PrevBtn,
    TabMenuNew,
    GeneralTab,
    PropertiesTab,
    DisplayTab,
    StyleTab,
    PreviewTab,
    NextBtn,
    SaveWidgetBtn,
    UpdateWidgetPopup,
    LoadingIcon,
    ToastPopup,
    UpdateWidgetModal,
  },

  beforeRouteLeave(to, from, next) {
    this.toPage = to.fullPath;

    if (this.saveBtnVisibility) {
      this.showUpdateWidgetModal = true;

      if (this.redirect) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    discardChanges() {
      this.showUpdateWidgetModal = false;
      this.redirect = true;
      this.$router.push(this.toPage);
    },

    returnToEditing() {
      this.showUpdateWidgetModal = false;
      this.redirect = false;
    },

    copyToClipboard() {
      this.showCopyToast = true;
      
      setTimeout(() => {
        this.showCopyToast = false;
      }, 3000);
    },

    firstUpdatedWidget(){
      this.widget.updated = 0;
      this.isUpdated = false;
      this.saveWidget();
    },
    forceUpdateMe(){
      this.isForceUpdate = false;

      this.$nextTick(() => {
        this.isForceUpdate = true;
        setTimeout(()=> {
          if(this.active_tab === 'preview') {
           (
             document.getElementById(this.active_tab + "_tab") as HTMLFormElement
           ).style.display = "block";
          }
          }, 0)
      });
    },
    loadTab: function (info: {tabName: string, index: number}) {
      if(this.isError) {
        return;
      }

      if(info.tabName == 'general'){this.prev = false}
      if(info.tabName == 'preview'){this.next = false}

      if (info.tabName != this.active_tab) {
        (
          document.getElementById(this.active_tab + "_tab") as HTMLFormElement
        ).style.display = "none";
        (
          document.getElementById(info.tabName + "_tab") as HTMLFormElement
        ).style.display = "block";

        this.activeIndex = info.index;
        this.active_tab = info.tabName;

        if (info.tabName !== "general") this.prev = true;
        if (info.tabName !== "preview") this.next = true;
      }
    },

    makeAvtive(name: string){
      if(name == 'general'){this.prev = false}
      if(name == 'preview'){this.next = false}
    },

    loadNextTab() {
      let active_el = this.menuElements.indexOf(this.active_tab);
      let NextEl;
      if(active_el === 4){
        NextEl = 0;
      }else{
        NextEl = active_el + 1;
      }
      this.makeAvtive(this.menuElements[NextEl]);

      if (this.menuElements[NextEl] != this.active_tab) {
        (
          document.getElementById(this.active_tab + "_tab") as HTMLFormElement
        ).style.display = "none";
        (
          document.getElementById(this.menuElements[NextEl] + "_tab") as HTMLFormElement
        ).style.display = "block";

        this.active_tab = this.menuElements[NextEl];
        this.activeIndex = NextEl;

        if (this.menuElements[NextEl] !== "general") this.prev = true;
        if (this.menuElements[NextEl] !== "preview") this.next = true;
      }
    },

    loadPrevBtn(){
      let active_el = this.menuElements.indexOf(this.active_tab);
      let NextEl = 0;
      if(active_el === 0){
        NextEl = 4;
      }else{
        NextEl = active_el - 1;
      }
      this.makeAvtive(this.menuElements[NextEl]);
      if (this.menuElements[NextEl] != this.active_tab) {
        (
          document.getElementById(this.active_tab + "_tab") as HTMLFormElement
        ).style.display = "none";
        (
          document.getElementById(this.menuElements[NextEl] + "_tab") as HTMLFormElement
        ).style.display = "block";

        this.active_tab = this.menuElements[NextEl];
        this.activeIndex = NextEl;

        if (this.menuElements[NextEl] !== "general") this.prev = true;
        if (this.menuElements[NextEl] !== "preview") this.next = true;
      }
    },

    loadWidget() {
      if(this.isError) {
        return;
      }

      axiosClient.get("v4/widget/" + this.$route.params.wid).then((res) => {
        this.widget = res.data.widget;
        this.paymentMethods = res.data.payment
        this.widget.edited = false;
        this.widget.config = JSON.parse(res.data.widget.config);

        if( parseInt(res.data.widget.version) == 3 ){
          this.isOldWidget = true;
        }

        if( parseInt(res.data.widget.updated) === 1 ){
          this.isUpdated = true;
        }

        sessionStorage.setItem(
          "payment_methods",
          JSON.stringify(res.data.payment)
        );
        sessionStorage.setItem(this.widget.id, JSON.stringify(this.widget));
      }).catch((error) => {
        if(error.response == undefined){
          this.errorMessage = 'Internal Server Error'
          return;
        }
        if(error.response.status === 500) {
          this.errorMessage = 'Internal Server Error'
        } else {
          this.errorMessage = 'Widget is not found'
        }
        this.isError = true;
      });
    },
    loadWidgetInfoByApi() {
      axiosClient.get("v3/dashboard").then((res) => {
        this.widgetInfo = res.data;
      }).catch((error) => {
        if(error.response.status === 500) {
          this.errorMessage = 'Internal Server Error'
        } else {
          this.errorMessage = 'Widget is not found'
        }
        this.isError = true;
      });
    },
    
    updateWidget: function (widget: WidgetI) {
      if (widget.config.enable_card_payments) {
        this.isSaveWidget = false;
        
        if (widget.config.payment_gateway_api && widget.config.payment_gateway_config) {
          this.isSaveWidget = validatePaymentConfig(widget.config.payment_gateway_api, widget.config.payment_gateway_config);
        }
      } else {
        this.isSaveWidget = true;
      }

      // if we disable booking, but leave some data in payemnt config, still enable saving widget
      if (!widget.config.enable_bookings && widget.config.enable_card_payments) {
        this.isSaveWidget = true;
      }

      if (
        !widget.name
        || widget.name.length > WIDGET_NAME_MAX_LENGTH
        || (widget.description && widget.description.length > WIDGET_DESCRIPTION_MAX_LENGTH)
        || !this.isSaveWidget
      ) {
        this.widget.edited = false;
        this.isSaveLabel = false;
        this.saveBtnVisibility = false;

        return;
      }
      
      this.widget = widget;
      this.widget.edited = true;
      this.isSaveLabel = true;
      this.saveBtnVisibility = true;
    },
    
    updateMyWidget(){
      this.widget.version = '4.0';
      this.widget.updated = '1';
      this.isLoadIcon = true;
      this.saveWidget();
    },
    saveWidget() {
      axiosClient
        .put("v3/widgets/" + this.$route.params.wid, this.widget)
        .then(() => {
          this.loadInfo();
          this.forceUpdateMe();
          this.$forceUpdate();
          this.isLoadIcon = false;
        }).catch((error) => {
        if(error.response.status === 500) {
          this.errorMessage = 'Internal Server Error'
        } else {
          this.errorMessage = 'Widget is not found'
        }
        this.isError = true;
      });

      this.saveBtnVisibility = false;
      this.isSaveLabel = false;
      this.widget.edited = false;
    },
    loadInfo(){
      this.loadWidget();
      this.loadWidgetInfoByApi();
    }
  },

  mounted() {
    this.loadInfo();
  },

  watch: {
    isError(value: boolean) {
      if (value) {
        this.showErrorToast = true;
      }
    },
    
    saveBtnVisibility(value: boolean) {
      store.commit('setSaveBtnVisibility', value);
    }
  },

  data() {
    return {
      isSaveWidget: true,
      isLoadIcon: false,
      isError: false,
      errorMessage: '',
      isUpdated: false,
      isOldWidget: false,
      isSaveLabel: false,
      widget: {} as WidgetI,
      paymentMethods: {} as PaymentMethodsI,
      widgetInfo: {},
      prev: false,
      next: true,
      active_tab: "general",
      saveBtnVisibility: false,
      nextElement: 'properties',
      prevElement: 'style',
      activeIndex: 0,
      menuElements: [
        'general',
        'properties',
        'display',
        'style',
        'preview'
      ],
      isForceUpdate: true,
      showErrorToast: false,
      showCopyToast: false,
      showUpdateWidgetModal: false,
      redirect: false,
      toPage: '',

      changeCase: changeCase,
      ElementE: ElementE,
    };
  },
});
