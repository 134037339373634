import { createApp, Directive } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style.css';

const app = createApp(App);

const clickOutside: Directive = {
    beforeMount: (el: { clickOutsideEvent: { (event: any): void; (this: Document, ev: MouseEvent): any; };
        contains: (arg0: any) => any; }, binding: { value: () => void; }) => {
        el.clickOutsideEvent = event => {
            if (!(el == event.target || el.contains(event.target))) {
                binding.value();
            }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: (el: { clickOutsideEvent: (this: Document, ev: MouseEvent) => any; }) => {
        document.removeEventListener("click", el.clickOutsideEvent);
    },
};

app.directive("click-outside", clickOutside).use(store).use(router).mount('#app')
