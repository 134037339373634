<template>
  <div class="flex mb-5">
    <button
      @click.prevent="sendId"
      class="btn-secondary is-btn-size-default flex items-center gap-1"
      :class="!visible ? 'hidden' : ''">
      <LeftIcon />
      {{ changeCase(title, ElementE.BUTTON) }}
    </button>
  </div>
</template>

<script>
import LeftIcon from '../icons/LeftIcon.vue';
import { changeCase } from '../../helpers';
import { ElementE } from '../../enums';

export default {
  name: "PrevBtn",
  components: { LeftIcon },
  props: {
    title: {
      type: String,
      default: "Back"
    },
    visible:{
      default: false
    }
  },
  emits: ['sendTabId'],
  data(){
    return {
      tab: 'properties',

      changeCase: changeCase,
      ElementE: ElementE,
    }
  },
  methods: {
    sendId: function(){
      this.$emit('sendTabId', this.tab)
    },
  }
}
</script>
