
import { defineComponent } from "vue";
import DropdownMenuIcon from "../icons/RightArrowIcon.vue";
import QuestionMarkIcon from "../icons/QuestionMarkIcon.vue";
import { changeCase } from "../../helpers";
import { ElementE } from '../../enums';
import { IShowDay, IShowVariant } from "../edittabs/constants";

export default defineComponent({
  name: "DropdownMenu",
  components: { DropdownMenuIcon, QuestionMarkIcon },
  props: {
    label: { type: String, required: true },
    defaultSelect: { type: String, default: "Select value" },
    values: {
      type: Array as () => (IShowDay | IShowVariant)[],
      default: () => [] as (IShowDay | IShowVariant)[],
    },
  },
  emits: ["input"],
  data() {
    return {
      isOptionsListVisible: false,
      selectedValue: this.defaultSelect,
      showTooltipTxt: false,
      tooltipTxt:
        "Select the type of information shown in each cell of the matrix, such as available rooms, prices, or text.",

      changeCase: changeCase,
      ElementE: ElementE,
    };
  },

  methods: {
    clickOutside() {
      this.isOptionsListVisible = false;
    },
    handleSelect(event: MouseEvent) {
      this.selectedValue = (event.target as HTMLElement).innerText;
      this.$emit("input", (event.target as HTMLElement).id);
    },
  },
});
