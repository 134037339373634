
import { defineComponent } from "vue";
import InfoIcon from "../icons/InfoIcon.vue";
import axiosClient from "@/axiosClient";
import { changeCase } from '../../helpers';
import { ElementE } from '../../enums';

export default defineComponent({
  name: "ClearCacheModal",
  components: { InfoIcon },
  emits: ['close', 'clearCache'],
  methods:{
    clearCache(){
      axiosClient
        .get("v3/clearcache")
        .then((res) => {
          this.$emit('clearCache', 'Cache cleared');
        }).catch(e =>{
        console.log(e)
      })
    }
  },
  data() {
    return {
      changeCase: changeCase,
      ElementE: ElementE,
    }
  },
});
