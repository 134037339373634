<template>
  <div class="inline-block text-neutral-125 group">
    <svg
      width="24"
      height="14"
      viewBox="0 0 24 14"
      class="group-hover:text-primary-main fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333008 12.8333C0.333008 13.475 0.858008 14 1.49967 14H22.4997C23.1413 14 23.6663 13.475 23.6663 12.8333C23.6663 12.1917 23.1413 11.6667 22.4997 11.6667H1.49967C0.858008 11.6667 0.333008 12.1917 0.333008 12.8333ZM0.333008 7C0.333008 7.64167 0.858008 8.16667 1.49967 8.16667H22.4997C23.1413 8.16667 23.6663 7.64167 23.6663 7C23.6663 6.35833 23.1413 5.83333 22.4997 5.83333H1.49967C0.858008 5.83333 0.333008 6.35833 0.333008 7ZM0.333008 1.16667C0.333008 1.80833 0.858008 2.33333 1.49967 2.33333H22.4997C23.1413 2.33333 23.6663 1.80833 23.6663 1.16667C23.6663 0.525 23.1413 0 22.4997 0H1.49967C0.858008 0 0.333008 0.525 0.333008 1.16667Z"
      />
    </svg>
  </div>
</template>
