
import { defineComponent } from "vue";
import axios from "axios";
import DeleteWidgetModal from "./modals/DeleteWidgetModal.vue";
import TooltipIcon from "./icons/TooltipIcon.vue";
import TrashCanIcon from "./icons/TrashCanIcon.vue";

export default defineComponent({
  name: "WidgetCard",
  components: { DeleteWidgetModal, TooltipIcon, TrashCanIcon },
  props: {
    id: { type: String, require: true },
    title: { type: String },
    widget_id: { type: String },
    widget_description: { type: String, default: "" },
    isFirst: { type: Boolean },
    isLast: { type: Boolean },
  },
  emits: ["deleted"],

  data() {
    return {
      descriptionVisibility: false,
      isDeleteWidgetModalVisible: false,
    };
  },

  computed: {
    roundedClasses() {
      if (this.isFirst) {
        return 'rounded-t-lg'
      }

      if (this.isLast) {
        return 'rounded-b-lg'
      }

      return ''
    }
  },

  methods: {
    async deleteWidget(id: string) {
      const token = sessionStorage.getItem("TOKEN");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        await axios.delete(
          `${process.env.VUE_APP_API}v3/widgets/${id}`,
          config
        );

        this.isDeleteWidgetModalVisible = false;

        this.$emit('deleted', id, this.title);
      } catch (error) {
        console.error("error deleting widget");
      }
    },
  },
});
