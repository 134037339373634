<template>
  <div
    class="relative w-full pt-2 z-10"
    @click="isOptionsListVisible = !isOptionsListVisible"
    v-click-outside="clickOutside"
  >
    <div class="relative">
      <div class="label-primary">
        {{ changeCase(label, ElementE.LABEL) }}
      </div>

      <div
        class="p-3 border border-gray-300 rounded-lg text-sm text-neutral-125 cursor-default"
        :class="isOptionsListVisible && 'border-red-500'"
      >
        {{ selectedValue }}
      </div>
      <div
        class="absolute top-1/2 right-3 transform -translate-y-1/2 transition duration-300"
        :class="isOptionsListVisible ? '-rotate-90' : 'rotate-90'"
      >
        <DropdownMenuIcon />
      </div>
    </div>

    <div
      v-if="isOptionsListVisible"
      class="absolute w-full mt-2 border border-gray-300 rounded-lg"
      :class="
        values.length > 3 ? 'h-[180px] overflow-y-scroll' : 'overflow-hidden'
      "
    >
      <div
        v-for="item in this.payments"
        :key="item.id"
        :id="item.slug"
        :test="selectedValue"
        class="p-3 text-sm text-neutral-125 cursor-pointer hover:bg-neutral-0"
        @click="chooseMethod"
        :class="item.name === selectedValue ? 'bg-neutral-25' : 'bg-white'"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import DropdownMenuIcon from "@/components/icons/RightArrowIcon.vue";
import { changeCase } from "../../helpers";
import { ElementE } from '../../enums';

export default {
  name: "PaymentDropdown",
  components: { DropdownMenuIcon },
  props: {
    label: {
      type: String,
      required: true,
    },
    defaultSelect: {
      type: String,
      default: "Choose payment method",
    },
    defaultId: {
      type: String,
      default: "",
    },
    values: {
      type: [],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [{ item: "Example Item", id: 1 }],
    },
  },

  mounted() {
    this.defaultValue = this.defaultSelect;
  },
  watch: {
    defaultId: function (id) {
      this.defaultIdField = id;
    },
    defaultSelect(defaultVal){
      let paymentObj = this.payments.filter(item => {
        return item.slug === defaultVal
      })
      if(paymentObj[0] !== undefined && paymentObj[0].name !== undefined){
        this.selectedValue = paymentObj[0].name;
        this.defaultIdField = paymentObj[0].id
        this.isLoaded = 1;
        this.currentPayments = this.payments.filter(item => {
            return item.slug !== paymentObj[0].slug
        });
      }
    },
    values: function (val) {
      this.payments = val;
      this.currentPayments = val;
    },
  },
  emits: ["input"],
  data(){
    return {
      payments:{},
      currentPayments:{},
      defaultIdField: 0,
      isLoaded:0,
      defaultValue: "Choose payment method",
      isOptionsListVisible: false,
      selectedValue: this.defaultSelect,
      
      changeCase: changeCase,
      ElementE: ElementE,
    };
  },
  methods: {
    clickOutside() {
      this.isOptionsListVisible = false;
    },
    chooseMethod(event) {
      const name = event.target.innerText;
      this.selectedValue = name;

      const pays = this.payments.filter(item => {
        return item.name == name;
      })

      if(pays[0] != undefined && pays[0].payment_fields != undefined){
        this.$emit('input', {
          slug: pays[0].slug,
          payOptions: pays[0].payment_fields
        });
      }

    }
  },
};
</script>
