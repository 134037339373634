<template>
  <div>
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      class="fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 3.75V14.25C2.25 15.075 2.9175 15.75 3.75 15.75H8.25V2.25H3.75C2.9175 2.25 2.25 2.925 2.25 3.75ZM14.25 2.25H9.75V8.25H15.75V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM9.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V9.75H9.75V15.75Z"
      />
    </svg>
  </div>
</template>
