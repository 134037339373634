import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "label-primary flex gap-1.5" }
const _hoisted_3 = {
  key: 0,
  class: "-mt-0.5 bg-white"
}
const _hoisted_4 = {
  key: 0,
  class: "absolute w-64 p-4 rounded-lg bg-white z-10 shadow-secondary"
}
const _hoisted_5 = { class: "tooltip-content" }
const _hoisted_6 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionMarkIcon = _resolveComponent("QuestionMarkIcon")!
  const _component_DropdownMenuIcon = _resolveComponent("DropdownMenuIcon")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "relative w-full pt-2 z-10",
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isOptionsListVisible = !_ctx.isOptionsListVisible))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.changeCase(_ctx.label, _ctx.ElementE.LABEL)) + " ", 1),
        (_ctx.label === 'Matrix display details')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_QuestionMarkIcon, {
                onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTooltipTxt = true)),
                onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showTooltipTxt = false))
              }),
              (_ctx.showTooltipTxt)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.tooltipTxt), 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["p-3 border border-gray-300 rounded-lg text-sm text-neutral-125 cursor-default", _ctx.isOptionsListVisible && 'border-red-500'])
      }, _toDisplayString(_ctx.changeCase(_ctx.selectedValue, _ctx.ElementE.DROPDOWN)), 3),
      _createElementVNode("div", {
        class: _normalizeClass(["absolute top-1/2 right-3 transform -translate-y-1/2 transition duration-300", _ctx.isOptionsListVisible ? '-rotate-90' : 'rotate-90'])
      }, [
        _createVNode(_component_DropdownMenuIcon)
      ], 2)
    ]),
    (_ctx.isOptionsListVisible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["absolute w-full mt-2 border border-gray-300 rounded-lg", 
        _ctx.values.length > 3 ? 'h-[180px] overflow-y-scroll' : 'overflow-hidden'
      ])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              id: (item.id as string),
              class: _normalizeClass(["p-3 text-sm text-neutral-125 cursor-pointer hover:bg-neutral-0", item.name.toString() === _ctx.selectedValue ? 'bg-neutral-25' : 'bg-white']),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleSelect && _ctx.handleSelect(...args)))
            }, _toDisplayString(_ctx.changeCase(item.name, _ctx.ElementE.DROPDOWN)), 11, _hoisted_6))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, _ctx.clickOutside]
  ])
}