import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex tab-wrapper" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex flex-col items-center" }
const _hoisted_4 = { class: "mt-1 text-current whitespace-nowrap" }
const _hoisted_5 = {
  key: 0,
  class: "absolute -bottom-[7px] left-1/2 -translate-x-1/2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabArrowDownIcon = _resolveComponent("TabArrowDownIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, ({ name, id, displayName }, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: id,
        class: _normalizeClass(["relative w-1/5 px-12 py-3 text-neutral-75 bg-neutral-0 cursor-pointer transition-all duration-500 hover:text-primary-main hover:bg-white hover:shadow-primary", [
        i === _ctx.activeIndex && 'active-tab',
        i === 0 && 'first-tab',
        i === _ctx.tabs.length - 1 && 'last-tab',
        (i > 0 && i < _ctx.tabs.length - 1) && 'middle-tabs',
        i === Math.floor(_ctx.tabs.length / 2) && 'center-tab',
        name + '_t',
      ]]),
        onClick: ($event: any) => (_ctx.handleClick(name, i))
      }, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tabIcon(name)), { class: "text-current" })),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.changeCase(displayName, _ctx.ElementE.TITLE)), 1)
        ]),
        (i === _ctx.activeIndex)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_TabArrowDownIcon)
            ]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}