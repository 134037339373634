<template>
  <div class="inline-block text-neutral-125 group">
    <svg
      width="24"
      height="14"
      viewBox="0 0 18 18"
      class="group-hover:text-primary-main fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4009 0.61305C16.8809 0.0930504 16.0409 0.0930504 15.5209 0.61305L9.00094 7.11972L2.48094 0.599717C1.96094 0.0797168 1.12094 0.0797168 0.600937 0.599717C0.0809375 1.11972 0.0809375 1.95972 0.600937 2.47972L7.12094 8.99972L0.600937 15.5197C0.0809375 16.0397 0.0809375 16.8797 0.600937 17.3997C1.12094 17.9197 1.96094 17.9197 2.48094 17.3997L9.00094 10.8797L15.5209 17.3997C16.0409 17.9197 16.8809 17.9197 17.4009 17.3997C17.9209 16.8797 17.9209 16.0397 17.4009 15.5197L10.8809 8.99972L17.4009 2.47972C17.9076 1.97305 17.9076 1.11972 17.4009 0.61305Z"
      />
    </svg>
  </div>
</template>
