<template>
  <div>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 13.3335H3.33333V4.66683C3.33333 4.30016 3.03333 4.00016 2.66667 4.00016C2.3 4.00016 2 4.30016 2 4.66683V13.3335C2 14.0668 2.6 14.6668 3.33333 14.6668H10C10.3667 14.6668 10.6667 14.3668 10.6667 14.0002C10.6667 13.6335 10.3667 13.3335 10 13.3335ZM13.3333 10.6668V2.66683C13.3333 1.9335 12.7333 1.3335 12 1.3335H6C5.26667 1.3335 4.66667 1.9335 4.66667 2.66683V10.6668C4.66667 11.4002 5.26667 12.0002 6 12.0002H12C12.7333 12.0002 13.3333 11.4002 13.3333 10.6668ZM12 10.6668H6V2.66683H12V10.6668Z"
        fill="#666666"
      />
    </svg>
  </div>
</template>
