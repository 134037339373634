import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.saveWidget && _ctx.saveWidget(...args)), ["prevent"])),
          class: "btn-primary is-btn-size-default"
        }, _toDisplayString(_ctx.changeCase(_ctx.title, _ctx.ElementE.BUTTON)), 1)
      ]))
    : _createCommentVNode("", true)
}