<template>
  <div class="mb-5">
    <button
      @click.prevent="sendId"
      class="btn-primary is-btn-size-default flex items-center gap-1"
      :class="!visible ? 'hidden' : ''"
    >
      {{ changeCase(title, ElementE.BUTTON) }}
      <RightIcon />
    </button>
  </div>
</template>

<script>
import RightIcon from '../icons/RightIcon.vue';
import { changeCase } from '../../helpers';
import { ElementE } from '../../enums';

export default {
  name: "NextBtn",
  components: { RightIcon },
  props: {
    title: {
      type: String,
      default: "Next"
    },
    visible: {
      default: true
    }
  },
  emits: ['sendTabId'],
  data(){
    return {
      tab: 'properties',

      changeCase: changeCase,
      ElementE: ElementE,
    }
  },
  methods: {
    sendId: function(){
      this.$emit('sendTabId', this.tab)
    },
  }
}
</script>
