<template>
  <div class="text-neutral-75 group">
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      class="group-hover:text-neutral-125 fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00033 0.666687C4.40033 0.666687 0.666992 4.40002 0.666992 9.00002C0.666992 13.6 4.40033 17.3334 9.00033 17.3334C13.6003 17.3334 17.3337 13.6 17.3337 9.00002C17.3337 4.40002 13.6003 0.666687 9.00033 0.666687ZM9.00033 13.1667C8.54199 13.1667 8.16699 12.7917 8.16699 12.3334V9.00002C8.16699 8.54169 8.54199 8.16669 9.00033 8.16669C9.45866 8.16669 9.83366 8.54169 9.83366 9.00002V12.3334C9.83366 12.7917 9.45866 13.1667 9.00033 13.1667ZM9.83366 6.50002H8.16699V4.83335H9.83366V6.50002Z"
      />
    </svg>
  </div>
</template>
