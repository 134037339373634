import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "uwf",
  class: "flex flex-col gap-6 w-[650px] p-6 rounded-lg bg-white shadow-default"
}
const _hoisted_2 = { class: "modal-heading" }
const _hoisted_3 = { class: "modal-content mt-1" }
const _hoisted_4 = { class: "flex gap-4 self-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.changeCase('you have unpublished changes', _ctx.ElementE.TITLE)), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.changeCase('if you leave now, your changes will be discarded.', _ctx.ElementE.SUBTITLE)), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "btn-secondary is-btn-size-default",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('discardChanges')))
      }, _toDisplayString(_ctx.changeCase('discard changes', _ctx.ElementE.BUTTON)), 1),
      _createElementVNode("button", {
        class: "btn-primary is-btn-size-default",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('returnToEditing')))
      }, _toDisplayString(_ctx.changeCase('return to editing', _ctx.ElementE.BUTTON)), 1)
    ])
  ]))
}