<template>
  <div class="w-full pt-2">
    <div class="relative">
      <label class="label-primary" for="">
        {{ changeCase(setLabel(label), ElementE.LABEL) }}
      </label>
      <input
        :id="id"
        :class="classes"
        :value="dvalue"
        @input="$emit('input', {id: $event.target.id, val: $event.target.value})"
        type="text"
        class="input-primary"
        :placeholder="placeholder && changeCase(placeholder, ElementE.PLACEHOLDER).toString()"
      />
    </div>
  </div>
</template>

<script>
import { PaymentLabel } from '../../constants/index';
import { changeCase } from "../../helpers";
import { ElementE } from '../../enums';

export default {
  name: "PaymentTextInput",
  props: ["label", "placeholder", "inpValue", "classes", "dvalue", "id"],
  emits: ["input"],
  watch: {
    dvalue: {
      handler(newVal) {
        if (newVal) {
          this.value = newVal;
        }
      }, deep: true
    },
  },
  data() {
    return {
      value: this.inpValue,

      changeCase: changeCase,
      ElementE: ElementE,
    };
  },
  methods: {
    sendId: function (item) {
      document.querySelectorAll(".tab").forEach((item) => {
        item.classList.remove("active");
      });
      document.querySelector("." + item).classList.add("active");

      this.$emit("input", item);
    },
    setLabel: function (label) {
     const gelPayment = PaymentLabel.find((item) => (item.fileName === label));
      return gelPayment === undefined ? label : gelPayment.title;
    },
  },
};
</script>
