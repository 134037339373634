export interface IShowDay {
    name: number;
    id: number;
}

export interface IShowVariant {
    name: string;
    id: string;
}

export const showDays: IShowDay[] = [
    { name: 5, id: 5 },
    { name: 7, id: 7 },
    { name: 10, id: 10 },
    { name: 14, id: 14 },
];

export const showVariant: IShowVariant[] = [
    { name: "Show Available Text", id: "availableText" },
    { name: "Show Prices", id: "rates" },
    { name: "Show Rooms Count", id: "availability" },
];
