<template>
  <div>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      class="fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6891 6.31093C14.2745 5.89636 13.6048 5.89636 13.1902 6.31093L8.31093 11.1902C7.89636 11.6048 7.89636 12.2745 8.31093 12.6891L13.1902 17.5683C13.6048 17.9829 14.2745 17.9829 14.6891 17.5683C15.1036 17.1538 15.1036 16.4841 14.6891 16.0695L10.5645 11.9343L14.6891 7.80979C15.1036 7.39522 15.093 6.71488 14.6891 6.31093Z"
      />
    </svg>
  </div>
</template>
