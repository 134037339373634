type NameT = "general" | "properties" | "display" | "style" | "preview";

export interface TabI {
    id: number;
    name: NameT;
    displayName: Exclude<NameT, "preview"> | "preview & publish";
}

export const tabs: TabI[] = [
    { id: 1, name: "general", displayName: "general" },
    { id: 2, name: "properties", displayName: "properties" },
    { id: 3, name: "display", displayName: "display" },
    { id: 4, name: "style", displayName: "style" },
    { id: 5, name: "preview", displayName: "preview & publish" },
];
